import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import {
  Section,
  Container,
  Row,
  Column,
  Text,
  Image,
  ScrollTo,
  Video,
  RollingClutch,
} from 'components/atoms';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import TagsContainer from 'components/molecules/TagsContainer';

import { useWindowSize } from 'utils/windowSizeHook';
import { htmlCheck } from 'utils/html';

const BannerWithImage = ({ data, clutch, className, breadcrumbsData }) => {
  const width = useWindowSize();
  const [isDesktop, setIsDesktop] = useState(width > 1024 ? true : false);
  const { caption, banner_media, link } = data;
  const { type, image, video } = banner_media || {};
  const { source_url: image_link, alt_text: image_alt, media_details } =
    image || {};
  const { source_url: video_link } = video || {};
  const { subtitle, title } = caption;
  const { text, path } = link;

  if (width) {
    let newWidth = width > 1024 ? true : false;
    if (isDesktop !== newWidth) {
      setIsDesktop(newWidth);
    }
  }
  const titleCheck = htmlCheck(title);
  const subtitleCheck = htmlCheck(subtitle);
  const isVideo = type === 'video';

  const [videoSrc, setVideoSrc] = useState(null);
  const videoRef = useRef(null);

  useEffect(() => {
    if (isVideo && isDesktop && !videoSrc) {
      setVideoSrc(video_link);
    }
  }, [isDesktop]);

  useEffect(() => {
    if (videoSrc && videoRef.current) {
      videoRef.current.play();
    }
  }, [videoSrc]);

  return (
    <Section className={className}>
      <Container>
        <Row>
          <Column
            className="banner-with-image__description offset-1 offset-lg-0"
            col="10"
            lg="5"
          >
            {titleCheck ? (
              <TagsContainer
                className={cn(
                  'tags-container_banner',
                  'tags-container_banner-title'
                )}
              >
                {title}
              </TagsContainer>
            ) : (
              <Text className="banner-with-image__description__title" tag="h1">
                {title}
              </Text>
            )}
            {subtitleCheck ? (
              <TagsContainer className={cn('tags-container_banner')}>
                {subtitle}
              </TagsContainer>
            ) : (
              <Text className="banner-with-image__description__subtitle body-lg">
                {subtitle}
              </Text>
            )}
          </Column>
          <Column
            className="offset-1"
            col="10"
            lg="5"
            onMouseDown={e => e.preventDefault()}
            onContextMenu={e => e.preventDefault()}
          >
            <div
              className={cn('banner-with-image__media-wrapper', {
                'banner-with-image__media-wrapper_img': !isVideo,
              })}
            >
              <video
                ref={videoRef}
                autoPlay={true}
                controls={false}
                loop={true}
                muted={true}
                src={videoSrc}
              />
              <Image
                src={image_link}
                alt={image_alt || title}
                image={media_details}
              />
            </div>
          </Column>
        </Row>
        <ScrollTo
          className="banner-with-image__button"
          title={text}
          anchor={path}
        />
        <Breadcrumbs {...breadcrumbsData} />
        {clutch && <RollingClutch />}
      </Container>
    </Section>
  );
};

BannerWithImage.defaultProps = {
  className: 'banner-with-image',
};

BannerWithImage.propTypes = {
  data: PropTypes.object.isRequired,
  breadcrumbsData: PropTypes.object,
  className: PropTypes.string,
};

export default BannerWithImage;
