import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const TagsContainer = ({ children, className, isLight }) => {
  return (
    <div
      className={cn(
        'tags-container',
        { 'tags-container_light': isLight },
        { 'tags-container_dark': !isLight },
        [className]
      )}
      dangerouslySetInnerHTML={{ __html: children }}
    ></div>
  );
};

TagsContainer.defaultProps = {
  className: '',
};

TagsContainer.propTypes = {
  className: PropTypes.string,
};

export default TagsContainer;
